export const termsTxt = `כללי
קייטרינג גבאי בע"מ (להלן: " קייטרינג גבאי") בעלת ומפעילת שירותי קייטרינג "קייטרינג גבאי" משיקה בזאת
אתר אינטרנט עבור שירות הזמנת משלוחים באתר האינטרנט והמוקד הטלפוני 0532441019) להלן
:"האתר").
השימוש באתר כפוף להסכמת המשתמש (להלן: "המשתמש" ו/או "המזמין") לעשות בו שימוש
בהתאם לכללים שיפורטו להלן וכן בהתאם להוראות כל חוק ודין לרבות אך לא רק הוראות שיחולו על
שימוש באתרים, כפי שיהיו מעת לעת (להלן : "הוראות הדין").
להלן כללי השימוש באתר. שימוש באתר משמעו אישור והסכמה לקבלת כללי השימוש והתחייבות
לפעולה בהתאם להם כמו גם בהתאם להוראות הדין.
במקרה של הפרת כללי השימוש, רשאית קייטרינג גבאי ו/או מי מטעמה ו/או כל רשות להביא להפסקת
השימוש ע"י המשתמש ו/או לנקוט באמצעים שונים, לרבות בהליכים משפטיים.
תנאי השימוש נכתבו בלשון זכר מטעמי נוחות בלבד אך מיועדים לגברים ולנשים כאחד.
שירות המשלוחים
הזמנת משלוח מותנית במילוי פרטי המשתמש בטופס ההרשמה באתר ובהחלטה של " קייטרינג גבאי בע"מ" אם לתת שירותי משלוחים. על המשתמש למלא טופס
הרשמה זה בעת הזמנת מוצרים באתר. המשתמש מתחייב למסור פרטים מלאים, נכונים
ומדוייקים הכוללים בין היתר: שם, מספר ת.ז., כתובת, מספר טלפון , כתובת דואר אלקטרוני ושעת איסוף . אין
לעשות שימוש באתר תוך נטילת זהות בדויה ו/או התחזות לאדם אחר. הגשת פרטים כוזבים הינה
עבירה פלילית והעושה כן צפוי להליכים פליליים ואזרחיים.
מסירת פרטי המשתמש ונתונים אחרים אודותיו באתר או במוקד הטלפוני, תהווה הסכמה והיתר לקייטרינג גבאי בע"מ לעשות בהם שימוש לצורכי פרסום ולשם מתן שירותיה באמצעות אתר זה או באמצעות
הודעת מסר קצר לרבות, שיפור השירותים בכלל זה הסכמה לקבלת מסרים, הודעות ,תכתובות,
שאלונים, בדיקות תקופתיות, סקרים וכו' שנועדו לשפרם ולשפר את השירות כלפי לקוחות קייטרינג גבאי.
קייטרינג גבאי תערוך משלוחים עד לכתובת המשתמש באזורי החלוקה המוגדרים בלבד. יובהר כי
שירות המשלוחים מוגבל לאזורי חלוקה וקייטרינג גבאי תוכל לערוך שינויים באזורי החלוקה מעת לעת
ומבלי ליתן הודעה מראש.
שירות הזמנת משלוח באתר מותאם לשעות פעילות המשלוחים בסניף ועלול להשתנות מעת לעת
ומבלי ליתן הודעה מראש. זמן הגעה משוער יצוין בעת ביצוע ההזמנה וזאת בהתאם למועדי ההזמנה
ולסניף ממנו בוצעה ההזמנה. בכל מקרה, קייטרינג גבאי בע"מ מסירה מעל עצמה אחריות בנוגע לאיחור
באספקת המוצר בשל נסיבות שאינן מצויות בשליטתה ואשר יש בהן כדי לסכל את הגעתו של
המשלוח במועדו ובהן מסירת פרטים כוזבים בידי המזמין, מסירת פרטים בלתי מדוייקים בידי
המזמין, סגירת כבישים וחסימת התנועה בידי גורם חיצוני, אסון טבע, מצב חירום לאומי, מזג אוויר
סוער. רשימה זו הינה חלקית ואיננה ממצה נסיבות כאמור.
שירות הזמנת משלוח באתר מותנה בהזמנה בעלות מינימלית בסך 100 ₪ לא כולל דמי משלוח בסך
דמי המשלוח יקבעו ע"י קייטרינג גבאי.
אמצעי התשלום באתר הינו כרטיסי אשראי או מזומן
אשראי – אישור ההזמנה מותנה בשימוש בכרטיס אשראי בתוקף, ובאישור מטעם חברת האשראי.
מזומן – אישור ההזמנה מותנה בזיהוי המזמין באתר באמצעות שיחת טלפון.
 יובהר כי השלמת הזיהוי באתר תהווה אסמכ
משתמש שיעשה שימוש באתר בניגוד לכללי השימוש או ניגוד להוראות דין ישא לבדו באחריות
למעשיו לרבות לכל נזק ישיר ו/או עקיף שיגרם קייטרינג גבאי בע"מ ו/או לכל אדם אחר.
משתמש שיעשה שימוש באתר בניגוד לכללי השימוש או להוראות הדין כזה יהא מחויב לפצות את
קייטרינג גבאי בע"מ ולשפות אותה ו/או את עובדיה, מנהליה, בעלי מניותיה ,או מי מטעם אלו על כל נזק ישיר
ו/או עקיף לרבות הפסד, אבדן רווח, תשלום או הוצאה שייגרמו להם – ובכלל זה שכ"ט עו"ד והוצאות
משפט הכל אם דרישה ראשונה ועל פיה.
קייטרינג גבאי בע"מ רשאית להפסיק את השימוש באתר בכל מקרה של פעולה בניגוד לכללי השימוש ו/או
הוראות הדין ואולם אין באי הפסקת שימוש, כדי לפטור את המשתמש מאחריות כאמור לעיל ולהווה
איסור ו/או הסכמה לפעולה כאמור.
פטור מאחריות
פטור מאחריות של קייטרינג גבאי בע"מ, משמעו פטור מכל אחריות לנזק ישיר או עקיף למשתמש ו/או לכל
צד ג'.
ביטול עסקה לתקנת הגנת הצרכן [ ביטול עסקה ] התשע"א 2010 וחוק הגנת הצרכן , התשמ"א 1981 .
והיה אם תרצו לשלוח לנו הצעות לשיפור שירות, אנא שלחו את
הצעתכם למייל gabay.catering@gmail.com ואנו נטפל בפנייתכם בהקדם. חברת "קייטרינג גבאי בע"מ" מאחלת
לכם שימוש נעים וקל באתר!
`;
